.center {
  text-align: center;
}

.centerElement {
  margin-left: auto;
  margin-right: auto;
}
.profilePicture {
  float: left!important;
}

@media only screen and (max-width: 800px) {
  .profilePicture {
    float: unset!important;
  }
}