body {
  background-image: url("images/background-img.jpg");
  background-color: #052a58;
  background-repeat: no-repeat;
  background-size: cover;
  color: ghostwhite;
  opacity: 0.97;
  height: 100vh;
}

.blink {
  animation-name: blinker;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 2;

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
}

.dpool-title {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  width: 30%;
  left: -80px;
  right: 0px;
  color: white;

  > h2 {
    font-weight: 700;
    border-style: solid;
    border-width: 0 0 0.2rem 0;
    text-align: center;
    letter-spacing: 5px;
    padding-left: 30%;
    padding-right: 30%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 480px) {
  .dpool-title > h2 {
    padding-left: 0px;
    padding-right: 0px;
  }
}

button.rbc-btn {
  color: lightslategray !important;
}

.rbc-calendar {
  color: lightslategray;
}

.rbc-toolbar button {
  color: lightslategray;
}

.customer-card-table {
  border: "1px solid white";
  width: 100%;
}

.customer-card-table *> tr,
.customer-card-table *> th,
.customer-card-table *> td,
.product-delivery-table *> tr,
.product-delivery-table *> th,
.product-delivery-table *> td {
  border: 1px solid white;
  border-color: gray;
  padding: 10px 10px 10px 10px !important;
  vertical-align: middle !important;;
}

.missing-quantity {
  border-color: red !important;
  border-width: 3px !important;;
}
