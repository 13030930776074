@import "../node_modules/bootstrap/scss/bootstrap.scss";

// Used for top bar, side bar, buttons
$base-background-color: #308e9c;
$base-border-color: #17a2b8;

// Used for top bar, side bar, buttons on Hover
$base-background-color-hover: #145b66;
$base-border-color-hover: #117a8b;

// Used for main views background
$dark-background-color: #203b5c;

// Used for tables on the main views
$dark-table-color: #1f1f1f;

.dpool-action-link {
  @extend .btn;
  color: #17a2b8 !important;
  text-decoration: none !important;
  background-color: transparent !important;
  cursor: pointer;
}

.dpool-action-link-delete {
  @extend .dpool-action-link;
  color: red !important;
  text-decoration: none !important;
  background-color: transparent !important;
  cursor: pointer;
}

.dpool-action-link:hover {
  color: #145b66 !important;
  border-color: #117a8b !important;
  text-decoration: underline !important;
}

.bg-dpool {
  @extend .bg-info;
  background-color: $base-background-color !important;
  border-color: $base-border-color;
}

.bg-dark-dpool {
  @extend .bg-dark;
  background-color: $dark-background-color !important;

  *:disabled {
    background-color: $dark-background-color !important;
    color: #eee;
  }
}

.table-dark-dpool {
  @extend .table-dark;
  background-color: $dark-table-color !important;
}

.btn-dpool {
  @extend .btn-info;
  background-color: $base-background-color !important;
  border-color: $base-border-color;
}

.btn-dpool > a,
.btn-dpool > button {
  color: #fff !important;
  background-color: $base-background-color !important;
  border-color: $base-border-color !important;
}

.btn-dpool:hover,
.btn-dpool > a:hover,
.btn-dpool > button:hover {
  color: #fff !important;
  background-color: $base-background-color-hover !important;
  border-color: $base-border-color-hover !important;
  cursor: pointer;
}

input:invalid  {
  border: 2px solid red;
}

.requredSelect {
  display: none;
}

.requredSelect + div {
  border: 2px solid red;
}
